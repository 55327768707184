
//src/components/HelloWorld.vue
<template>
  <div>
    <EnvCheck />
  </div>
</template>

<script>
import EnvCheck from './EnvCheck.vue'
export default {
  name:'env-check',
  components: {
    EnvCheck
  },
  data() {
    return {
      
    };
  },
};
</script>
<style scoped>
</style>
