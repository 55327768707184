//src/components/EnvCheck.vue
<template>
<div>
  <div>
      <b-navbar toggleable="md" type="dark" variant="dark">
        <b-container>
            <b-navbar-brand href="#">
              <a class="kkbox-logo">
                <img width="160" src="https://pkg.kfs.io/kk-web/img/logo/kkbox-rebrand-white.svg" />
              </a>
              <span class="hd-title">{{hdTitle}}</span>
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown right>
                  <!-- Using 'button-content' slot -->
                  <template slot="button-content"><em>{{switchLang}}</em></template>
                  <b-dropdown-item href="#" @click="setLang('tc')">繁體中文</b-dropdown-item>
                  <b-dropdown-item href="#" @click="setLang('sc')">简体中文</b-dropdown-item>
                  <b-dropdown-item href="#" @click="setLang('en')" >English</b-dropdown-item>
                  <b-dropdown-item href="#" @click="setLang('jp')">日本語</b-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </b-container>
      </b-navbar>
    </div>


  <b-container  class="bv-example-row">

    <div v-if="userTestInfo" class="text-left">
      <b-row align-h="center">
        <b-col sm="10">
          <b-table :fields="fields" :items="items">
            <template v-slot:cell(title)="data">
              <div v-if = "data.value == 'forward'">
                <img  src="/img/computer-64x64.png" width="50px" height="50px"/>
              </div>
              <div v-else>
                <b class="text-info">
                  {{data.value}}
                </b>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

    </div>

    <div v-else>
       <b-row align-h="center">
        <b-col sm="10">
          Loading...
          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </b-col>
      </b-row>

    </div>
  </b-container>
</div>
</template>

<script>
  import axios from "axios";

  export default {

    data() {
      return {
        hdTitle : "System Diagnostic Tool",
        switchLang: "language",
        downloadUrl: "",
        windowsTool: "Windows Software Testing Tools",
        resultData: {},
        userTestInfo: false,
        lang: localStorage.getItem("lang") ?? 'en',
        fields: [
          {
            key: 'title',
            thStyle: { width: '150px'},
            label: ''
          },
          {
            key: 'value',
            label: ''
          }
        ],
        items: []

      };
    },
    created() {
      let apiUrl = "/api/getEnvInfo/" + this.lang;
      axios
        .get(apiUrl)
        .then(response => {
          this.resultData = response.data.data;
          this.userTestInfo = !this.info;
          this.hdTitle = this.resultData.message.diagnostic;
          this.switchLang = this.resultData.message.language;
          this.downloadUrl = this.resultData.envData.download_url;
          this.windowsTool = this.resultData.message.windows_tool;

          document.title = "KKBOX - " + this.hdTitle;
          document.documentElement.lang = navigator.language;

        this.items = [
          {
            title: 'forward',
            value: this.resultData.envData.forward
          },
          {
            title: this.resultData.message.status,
            value: this.resultData.message.normal
          },
          {
            title: this.resultData.message.region,
            value: this.resultData.envData.area
          },
          {
            title: this.resultData.message.isp,
            value: this.resultData.envData.isp
          },
          {
            title: this.resultData.message.ip,
            value: this.resultData.envData.remote_addr
          },
          {
            title: this.resultData.message.os,
            value: this.resultData.envData.os
          },
          {
            title: this.resultData.message.browser,
            value: this.resultData.envData.browser
          },
          {
            title: this.resultData.message.user_agent,
            value: this.resultData.envData.user_agent
          }
        ];
      })
      .catch(err => {
        console.log(err);
      });
    },
    computed: {

    },
    methods: {
      setLang(lang) {
        localStorage.setItem("lang", lang);
        location.reload();
      },
    }
  };
</script>
<style>
a.kkbox-logo{
  padding-right: 10px;
}

.btn {
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 20px;
  cursor: pointer;
}

/* Darker background on mouse-over */
.btn:hover {
  background-color: RoyalBlue;
}

.download-block {
  padding-bottom:20px;
}

.download-icon{
   padding-bottom:4px;
   padding-left: 6px;
}

.hd-title {
  font-size:23px;
  vertical-align:text-top;
}
</style>