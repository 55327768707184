//App.vue
<template>
  <div id="app">
    <AppNavbar />
    <AppBody />
  </div>
</template>
<script>
  import AppBody from './components/AppBody.vue'

  export default {
    name: 'navbar',
    components: {
      AppBody
    }
  }
</script>